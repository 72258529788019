<script setup lang="ts">
const { user } = useUser();
const activeTeam = useActiveTeam();

watch(
  user,
  (user) => {
    if (!user) {
      navigateTo("/auth/login");
    }
  },
  { immediate: true }
);

const jobsLink = computed(() => {
  return user.value?.isOnAdminTeam
    ? "/admin/jobs"
    : `/admin/teams/${activeTeam.value?.id}/jobs`;
});

const companiesLink = computed(() => {
  return user.value?.isOnAdminTeam
    ? "/admin/companies"
    : `/admin/teams/${activeTeam.value?.id}/companies`;
});

const closed = useLocalStorage("admin-menu-closed", false);
</script>
<template>
  <div
    v-if="user && activeTeam"
    class="w-[200px] h-full menu-wrapper bg-gray-100 border-r-2 border-gray-300 overflow-hidden"
    :class="{
      closed: closed,
    }"
  >
    <ul class="flex flex-col gap-1 p-2 menu">
      <li>
        <NuxtLink
          :to="jobsLink"
          :class="{
            active: $route.path.startsWith('/admin/jobs'),
          }"
        >
          <IconTruckOutline class="inline-block w-6 h-6" />
          <span class="menu-item-label"> Jobs </span>
        </NuxtLink>
      </li>
      <li v-if="user?.isOnAdminTeam">
        <NuxtLink
          to="/admin/users"
          :class="{
            active: $route.path.startsWith('/admin/users'),
          }"
        >
          <IconUsersOutline class="inline-block w-6 h-6" />
          <span class="menu-item-label"> All Users </span>
        </NuxtLink>
      </li>
      <li v-if="activeTeam.permissionsOnTeam.includes('users:read-team')">
        <NuxtLink
          :to="`/admin/teams/${activeTeam?.id}/users`"
          :class="{
            active:
              $route.path.startsWith('/admin/teams') &&
              $route.path.endsWith('/users'),
          }"
        >
          <IconUserOutline class="inline-block w-6 h-6" />
          <span class="menu-item-label">
            {{ user?.isOnAdminTeam ? "Admin Users" : "Users" }}
          </span>
        </NuxtLink>
      </li>
      <li v-if="user?.isOnAdminTeam">
        <NuxtLink
          :to="companiesLink"
          :class="{
            active: $route.path.startsWith('/admin/companies'),
          }"
        >
          <IconBuildingOffice2Outline class="inline-block w-6 h-6" />

          <span class="menu-item-label"> Companies </span>
        </NuxtLink>
      </li>
      <li v-if="user?.isOnAdminTeam">
        <NuxtLink
          to="/admin/feeds"
          :class="{
            active: $route.path.startsWith('/admin/feeds'),
          }"
        >
          <IconRssOutline class="inline-block w-6 h-6" />
          <span class="menu-item-label"> Feeds </span>
        </NuxtLink>
      </li>
      <li v-if="user?.isOnAdminTeam">
        <NuxtLink
          to="/admin/snippets"
          :class="{
            active: $route.path.startsWith('/admin/snippets'),
          }"
        >
          <IconCodeBracket class="inline-block w-6 h-6" />
          <span class="menu-item-label"> Snippets </span>
        </NuxtLink>
      </li>

      <li v-if="user?.isOnAdminTeam">
        <NuxtLink
          to="/admin/forms"
          :class="{
            active: $route.path.startsWith('/admin/forms'),
          }"
        >
          <IconListBullet class="inline-block w-6 h-6" />
          <span class="menu-item-label"> Forms </span>
        </NuxtLink>
      </li>

      <li class="!bg-transparent">
        <a @click.prevent="closed = !closed">
          <IconArrowLeftCircleOutline
            v-if="!closed"
            class="inline-block w-6 h-6"
          />
          <IconArrowRightCircleOutline v-else class="inline-block w-6 h-6" />
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.active {
  background-color: oklch(var(--bc) / 0.1);
}
.closed {
  width: 70px !important;
  overflow: hidden;
}
.menu-item-label {
  white-space: nowrap;
}

@keyframes slideClosed {
  0% {
    width: 200px;
  }
  100% {
    width: 0;
  }
}
.closed .menu-item-label {
  animation: slideClosed 0.2s ease-in-out;
  width: 0;
  display: inline-block;
  overflow: hidden;
}
.menu-wrapper {
  transition: width 0.2s ease-in-out;
}
</style>
