<template>
  <div>
    <AdminNavBar />
    <div class="flex flex-grow-0 below-nav">
      <AdminMainMenu />
      <div class="flex-grow w-4/5 h-full p-5 overflow-scroll">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style>
@import "@/assets/front-back-styles/index.css";
</style>

<style scoped>
.below-nav {
  height: calc(100vh - 60px);
}
</style>
