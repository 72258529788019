<script setup lang="ts">
const { user } = useUser();
const { site } = useAppConfig();

const activeTeam = useActiveTeam();
</script>
<template>
  <div class="bg-gray-100 border-b-2 border-gray-300 navbar">
    <div class="flex-1 text-slate-700">
      <NuxtLink to="/admin" class="text-xl normal-case btn btn-ghost">{{
        site
      }}</NuxtLink>
    </div>
    <div class="flex-none">
      <!-- <AppDropdownMenu class="mr-2">
        <template #trigger>
          {{ activeTeam?.name }} <IconChevronDown class="inline w-4" />
        </template>
        <li v-for="team in user?.teams">
          <button @click="activeTeam = team">{{ team.name }}</button>
        </li>
      </AppDropdownMenu> -->

      <AppDropdownMenu>
        <template #trigger>
          <div class="flex items-center">
            <span class="mr-2">{{ user?.name || user?.email }}</span>
            <div class="btn btn-ghost btn-circle avatar">
              <div class="w-10 rounded-full">
                <Transition>
                  <img
                    v-if="user?.avatar"
                    :src="user?.avatar"
                    class="w-10"
                    :key="1"
                  />
                  <IconUserCircle v-else class="w-10 h-10" :key="2" />
                </Transition>
              </div>
            </div>
          </div>
        </template>
        <li>
          <NuxtLink to="/admin/me" class="justify-between"> Profile </NuxtLink>
        </li>

        <li>
          <NuxtLink to="/auth/logout">Logout</NuxtLink>
        </li>
      </AppDropdownMenu>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
